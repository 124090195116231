import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';

import Login from '@/js/views/Login';
import Locations from '@/js/views/Locations';
import Location from '@/js/views/Location';
import Check from '@/js/views/Check';
import VuePaginate from 'vue-paginate';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VuePaginate)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      alias: '/app/login',
      name: 'login',
      component: Login
    },
    {
      path: '/app/locations',
      name: 'locations',
      component: Locations
    },
    {
      path: '/app/location/:id',
      name: 'location',
      component: Location
    },
    {
      path: '/app/check/:id',
      name: 'check',
      component: Check
    }
  ]
});

export default router;
