import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

const store = new Vuex.Store({
  state: {
    active: {},
    account: {
      id: undefined,
      first_name: '',
      last_name: '',
      email: ''
    },
    checks: [],
    isAuthenticated: false,
    locations: [],
    minors: [],
    position: {},
    isOnline: true,
    valid: [],
    data: [],
  },
  getters: {
    account: state => state.account,
    active: state => state.active,
    isAuthenticated: state => state.isAuthenticated,
    locations: state => state.locations,
    minors: state=> state.minors,
    position: state => state.position,
    isOnline: state => state.isOnline,
    checks: state => state.checks,
    valid: state => state.valid,
    data: state => state.data,
  },
  mutations: {
    authenticate: (state, authenticated) => {
      state.isAuthenticated = authenticated;
    },
    updateAccount: (state, account) => {
      state.account = account;
    },
    updateActive: (state, location) => {
      state.active = location;
    },
    updateLocations: (state, locations) => {
      state.locations = locations;
    },
    updateMinors: (state, minors) => {
      state.minors = minors;
    },
    updatePosition: (state, position) => {
      state.position = position;
    },
    updateIsOnline: (state, online) => {
      state.isOnline = online;
    },
    updateChecks: (state, check) => {
      state.checks.push(check);
    },
    updateValid: (state, valid) => {
      state.valid.push(valid);
    },
    updateData: (state, data) => {
      state.data.push(data);
    },
    clearChecks: (state, check) => {
      state.checks = check;
    },
    clearData: (state, data) => {
      state.data = data;
    },
    clearValid: (state, valid) => {
      state.valid = valid;
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
