<template>
  <div>
    <div id="offline-banner" class="offline-banner">You are offline</div>
    <router-view @authenticated="$store.getters.isAuthenticated" />
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store';
import app from '../vue';
export default {
  name: 'Application',
  data: function() {
    return {};
  },
  beforeCreate() {
    if (!this.$store.getters.isAuthenticated) {
      this.$router.replace({ name: 'login' });
    }
  },
  mounted() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const app = this;
      navigator.serviceWorker.register('/service-worker.js').then(
        function(registration) {
          console.log('%cServiceWorker registration successful with scope: ' + registration.scope, app.$log.success);
        },
        error => {
          console.log('%cServiceWorker registration failed: ' + error, app.$log.error);
        }
      );
    });
  }

  //creates a global state to show the online status of the page
  window.addEventListener('online', setOnlineStatus);
  window.addEventListener('offline', setOnlineStatus);
  function setOnlineStatus() {
    if(!navigator.onLine) {
      store.commit('updateIsOnline', false);
    } else {
      store.commit('updateIsOnline', true);
    }
  }

  //Watches for changes to the store and outputs updated value
  store.watch(
    (state, getters) => getters.isOnline,
    (newValue) => {
      //Offline banner
      if(!newValue) {
        document.getElementById('offline-banner').classList.add('show-banner');
      } else{
        document.getElementById('offline-banner').classList.remove('show-banner');
      }

      //Checks if there was form data submitted when user was offline
      if(newValue === true && store.getters.data[0] !== undefined) {
        var check = store.getters.checks;
        //Submits forms when back online
        check.forEach((check, index) => {
            console.log(check.store_id, store.getters.data[index].store_id, store.getters.valid[index]);
            this.submitForm(check, store.getters.data[index], store.getters.valid[index]);
        });
        //Clears form data
        store.commit('clearChecks', []);
        store.commit('clearData', []);
        store.commit('clearValid', []);
      }
    });

    //Checks to see if there was any form data submitted when user wasn't authenticated
    if(store.getters.checks[0] !== undefined) {
      //Submits forms that were submitted when logged back in
      store.getters.checks.forEach((check, index) => {
        this.submitForm(check, store.getters.data[index], store.getters.valid[index]);
      });
      //Clears form data
      store.commit('clearChecks', []);
      store.commit('clearData', []);
      store.commit('clearValid', []);
    }
  },
  methods: {
    //The submit form
    submitForm(check, data, valid) {
        //post request
        if(valid) {
        axios
        .post(
          this.$api.check,
          data,
          { headers: { 'Content-Type': 'application/json' } }
        )
        .then(response => {
          this.$router.push({ name: 'locations' });
          alert('Form submitted successfully!')
        })
        .catch(error => {
          this.message = 'There was an issue communicating with the server.';
          console.log('%c' + this.message, this.$log.error);
          //logs user out if they are not authenticated
          if(error.response.status === 401) {
            alert('Oops! Looks like you were logged out. Please log back in.');
            this.$router.push({ name: 'login' });
          }
        });
        } else {
        this.$forceUpdate();
        this.message = 'invalid data';
        console.log('%c' + this.message, this.$log.error);
        //Alert if user submitted invalid form data when offline
        alert('There was ' + this.message + ' for ' + store.getters.locations[check.store_id].name + '. Please re-submit the form with valid information.');
        //Clears any saved invalid form data
        store.commit('clearChecks', []);
        store.commit('clearData', []);
        store.commit('clearValid', []);
      }
    }
  },
}
</script>

<style lang="scss">
@import '../../sass/vue/configuration.scss';

// This makes use of what's in the overall configuration.
:root {
  @each $var-map-name, $var-map in $var-maps {
    @each $var, $val in $var-map {
      @if type-of($val) == map {
        @each $var-n, $val-n in $val {
          --#{$var-map-name}-#{$var}-#{$var-n}: #{$val-n};
        }
      } @else {
        --#{$var-map-name}-#{$var}: #{$val};
      }
    }
  }
  background-color: var(--color-athens-gray) !important;
  font-family: var(--font-family-primary) !important;
}
</style>
