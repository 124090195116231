import Vue from 'vue';
import Vuex from 'vuex';
import Routes from '@/js/routes.js';
import App from '@/js/views/App';
import store from './store';

Vue.use(Vuex);

Vue.prototype.$statuses = window.complianceCheckStatuses;
Vue.prototype.$items = window.complianceCheckPurchasableItems;

Vue.prototype.$api = {
  account: '/api/account',
  check: '/api/compliance_check',
  login: '/api/accounts/login',
  logout: '/api/accounts/logout',
  minors: '/api/minors',
  stores: '/api/stores'
};

Vue.prototype.$log = {
  success: [
    'font-family: Roboto, Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    'font-size: 16px',
    'color: #3CCC4E',
    'font-weight: bold'
  ].join(';'),

  warning: [
    'font-family: Roboto, Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    'font-size: 16px',
    'color: #F2B705',
    'font-weight: bold'
  ].join(';'),

  error: [
    'font-family: Roboto, Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    'font-size: 16px',
    'color: #F21905',
    'font-weight: bold'
  ].join(';')
};

const app = new Vue({
  el: '#app',
  store,
  router: Routes,
  render: h => h(App)
});

export default app;
