<template>
   <div class="container">
      <header class="background-header"></header>
      <div class="map" :class="{ hidden: mode !== 'map' }">
         <div id="map" style="height: 100%; width: 100%;"></div>
         <div class="row" v-if="mode === 'map' && selectedLocation" @click="goToLocation(selectedLocation)">
            <div class="left">
               <div class="top">
                  <span class="title">{{ selectedLocation.name }}</span>
                  <span class="tags">
                     <template v-if="selectedLocation.is_synar">
                        <span class="synar">Synar Store</span>
                     </template>
                     <template v-if="!selectedLocation.needs_recheck && selectedLocation.compliance_checks.length > 0 && selectedLocation.compliance_checks[0].passed">
                        <span class="passed">Passed</span>
                     </template>
                     <template v-else-if="!selectedLocation.needs_recheck && selectedLocation.compliance_checks.length > 0">
                        <span class="failed">Failed</span>
                     </template>
                     <template v-if="selectedLocation.needs_recheck">
                        <span class="recheck">Recheck</span>
                     </template>
                  </span>
               </div>
               <div class="bottom">
                  <span class="address">{{ selectedLocation.address }} {{ selectedLocation.city }}, {{ selectedLocation.state }}
                  {{ selectedLocation.zipcode }}</span>
                  <span class="distance">{{ selectedLocation.distance }} miles</span>
               </div>
            </div>
            <div class="right">
               <button class="arrow">
               <i class="icon icon-arrow-right-fill" />
               </button>
            </div>
         </div>
      </div>
      <div class="content">
         <div class="content-header">
            <div class="logo" @click="mode = 'list'; override = false;">
               <img src="/images/logo.png" />
            </div>
            <div class="account">
               {{ $store.getters.account.first_name }} {{ $store.getters.account.last_name }}
               <i class="icon icon-lock" @click="logout()" />
            </div>
         </div>
         <div class="filter">
            <div class="left">
               <input class="search" v-on:input="debounceInput" placeholder="Search store name or address…" />
               <button class="option" value="synar" :class="{ active: filters.synar }" @click="filters.nonsynar = false; filter($event)">
               Synar Stores
               </button>
               <button class="option" value="nonsynar" :class="{ active: filters.nonsynar }" @click="filters.synar = false; filter($event)">
               Non-Synar Stores
               </button>
               <button class="option" value="checked" :class="{ active: filters.checked }" @click="filters.checked = false; filter($event)">
               Checked Stores
               </button>
               <button class="option" value="unchecked" :class="{ active: filters.unchecked }" @click="filters.unchecked = false; filter($event)">
               Unchecked Stores
               </button>
               <button class="clear" @click="clear()"><i class="icon icon-x" />Clear All</button>
               <div class="radius" v-if="mode === 'map'">
                <input id="radius-checkbox" class="checkbox" type="checkbox" v-model="showResultsIn3Miles">
                <label for="radius-checkbox">Show results within 3 miles</label>
               </div>
            </div>
            <div class="right">
               <button class="toggle" :class="{ active: mode === 'list' }" @click="mode = 'list'">
               <i class="icon icon-list" /> List
               </button>
               <button class="toggle" :class="{ active: mode === 'map' }" @click="mode = 'map'">
               <i class="icon icon-map" /> Map
               </button>
            </div>
         </div>
         <div class="locations" v-if="mode === 'list'">
           <div v-if="filteredLocations.length">
              <paginate ref="paginator" name="filteredLocations" :list="filteredLocations" :per="10">
                <div class="row locations__item" @click="goToLocation(location)" v-for="location in paginated('filteredLocations')" v-bind:key="location.id">
                  <div class="left">
                      <div class="top">
                        <span class="title">{{ location.name }}</span>
                        <span class="tags">
                            <template v-if="location.is_synar">
                              <span class="synar">Synar Store</span>
                            </template>
                            <template v-if="!location.needs_recheck && location.compliance_checks.length > 0 && location.compliance_checks[0].passed">
                              <span class="passed">Passed</span>
                            </template>
                            <template v-else-if="!location.needs_recheck && location.compliance_checks.length > 0">
                              <span class="failed">Failed</span>
                            </template>
                            <template v-if="location.needs_recheck">
                              <span class="recheck">Recheck</span>
                            </template>
                        </span>
                      </div>
                      <div class="bottom">
                        <span class="address"
                            >{{ location.address }} {{ location.city }}, {{ location.state }} {{ location.zipcode }}</span
                            >
                        <span class="distance">{{ location.distance }} miles</span>
                      </div>
                  </div>
                  <div class="right">
                      <span class="arrow"><i class="icon icon-arrow-right-fill" /></span>
                  </div>
                </div>
              </paginate>
              <paginate-links for="filteredLocations" :limit="9" :show-step-links="true" :step-links="{next: '›', prev: '‹'}"></paginate-links>
          </div>
         </div>
      </div>
   </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  data: function() {
    return {
      filters: {
        synar: false,
        nonsynar: false,
        checked: false,
        unchecked: false
      },
      override: false,
      locations: [],
      filteredLocations: [],
      paginate: ['filteredLocations'],
      mode: 'list',
      showResultsIn3Miles: true,
      search: '',
      map: false,
      markers : []
    };
  },
  watch: {
    mode() {
      this.filterLocations();
    },
    showResultsIn3Miles() {
      this.filterLocations();
    }
  },
  computed: {
    selectedLocation() {
      return this.override;
    }
  },
  methods: {
    filterLocations() {
      var bounds = new google.maps.LatLngBounds();
      var threeMiles = false;
      const subset = this.$store.getters.locations;
      let filtered = subset.filter(location => {
        let address = [location.address, location.city, location.state, location.zipcode].join(' ');
        let searchName = location.name.toLowerCase().includes(this.search.toLowerCase());
        let searchAddress = address.toLowerCase().includes(this.search.toLowerCase());

        if (!searchName && !searchAddress) {
          if(searchName === '' && searchAddress === '') {
            return true;
          }
          return false;
        }

        if (this.filters.synar && !this.filters.nonsynar && !location.is_synar) {
          return false;
        }

        if (this.filters.nonsynar && !this.filters.synar && location.is_synar) {
          return false;
        }

        // @TODO We need to rework how we flag what's checked and what's not.
        if (this.filters.checked && !this.filters.unchecked && !location.check_this_year) {
          return false;
        }

        if (this.filters.unchecked && !this.filters.checked && location.check_this_year) {
          return false;
        }

        return true;
      })

      this.clearMarkers();

      let results= filtered.map((location) => {
        let icon = '';

        const checks = location.compliance_checks.sort((a, b) => (a.check_date_sortable < b.check_date_sortable) ? 1 : -1);;
        // If unchecked.
        if(checks.length === 0) {
          if(!location.is_synar) { // Not synar.
            icon = '/images/purple.png';
          } else {
            icon = '/images/lightblue.png';
          }
        } else { // Has been checked.
          if(!location.needs_recheck && checks[0].passed) { // Passed
            icon = '/images/green.png';
          } else {
            icon = '/images/red.png';
          }
          if(location.needs_recheck) {
            icon = '/images/blue.png';
          }
        }
        //Renders only markers that have been searched
        if(this.search !== '') {
          if (this.map) {
            if(location.latitude !== 0 && location.longitude !== 0) {
              let marker = new google.maps.Marker({
                position: { lat: location.latitude, lng: location.longitude },
                map: this.map,
                title: location.name,
                icon: {
                  url: icon
                }
              });
              marker.location = location
              marker.addListener('click', (event) => {
                this.override = location
              });
              this.markers.push(marker);

              if(marker.position.lat() !== null && marker.position.lng() !== null) {
                var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                bounds.extend(loc);
              }
            }
          }
        } else {
          //Only renders markers in a 3 mile radius
          var currentPos = this.$store.getters.position;
          var markerPos = {lat: location.latitude, lng: location.longitude}
          if(!this.showResultsIn3Miles || this.haversine(currentPos, markerPos) <= 3) {
            threeMiles = this.showResultsIn3Miles;
            if (this.map) {
              let marker = new google.maps.Marker({
                position: { lat: location.latitude, lng: location.longitude },
                map: this.map,
                title: location.name,
                icon: {
                  url: icon
                }
              });
              marker.location = location
              marker.addListener('click', (event) => {
                this.override = location
              });
              this.markers.push(marker);

              if(marker.position.lat() !== null && marker.position.lng() !== null) {
                var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                bounds.extend(loc);
              }
            }
          }
        }

        return location;
      });
      if(this.map) {
        if (results.length > 0) {
          this.map.fitBounds(bounds, 2);
          this.map.panToBounds(bounds);
        }

        //This is to prevent bad lat/lng data from making the zoom go to 0
        if(this.map.getZoom() < 6) {
          this.map.setZoom(6);
        }
        if(threeMiles === true) {
          this.map.setZoom(12);
        }
        if (results.length === 0) {
          const lat = this.$store.getters.position.lat || 0;
          const lng = this.$store.getters.position.lng || 0;
          this.map.setCenter(new google.maps.LatLng(lat, lng));
          this.map.setZoom(12);
        }
      }
      this.filteredLocations = results;
    },
    clearMarkers() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    },

    filter(event) {
      let target = event.target;
      let isActive = target.classList.contains('active');

      this.filters[target.value] = !isActive;
      this.filterLocations();

    },

    //Adds 500ms delay to user searching to reduce lag/freezing
    debounceInput: _.debounce(function (e) {
      this.search = e.target.value;
      this.page = 0;
      this.filterLocations();
    }, 750),

    clear() {
      this.override = false;
      this.page = 0;
      this.search = '';
      this.filters.synar = false;
      this.filters.nonsynar = false;
      this.filters.checked = false;
      this.filters.unchecked = false;
      this.filterLocations();
    },

    goToLocation(location) {
      this.$store.commit('updateActive', location);

      this.$router.push({
        name: 'location',
        params: location
      });
    },

    getMinors() {
      axios.get(this.$api.minors).then(response => {
        this.$store.commit('updateMinors', response.data);
        this.minors = this.$store.getters.minors;
      }).catch(error => {
        console.log('%cThere was an issue communicating with the server.', this.$log.error);
        this.minors = this.$store.getters.minors;
      });
    },

    async getLocations() {
      return axios.get(this.$api.stores).then(response => {
        this.$store.commit('updateLocations', response.data);
        this.locations = this.$store.getters.locations;
        this.getDistances();
      }).catch(error => {
        console.log('%cThere was an issue communicating with the server.', this.$log.error);
        this.locations = this.$store.getters.locations;
        this.getDistances();
        var online = this.$store.getters.isOnline;

        //Logs user out if they are online but unauthenticated
        if(error.response.status === 401 && online === true) {
          alert('Oops! Looks like you have been logged out. Please log back in.');
          this.$store.commit('authenticate', false);
          this.$router.push({ name: 'login' });
        }
      });
    },

    getDistances() {
      const position = this.$store.getters.position;
      return new Promise(resolve => {
        this.locations = this.locations.map((location, index) => {
          const distance = this.haversine(position, { lat: location.latitude, lng: location.longitude });
          return { ...location, distance };
        });
        this.locations.sort((a, b) => {
          return parseFloat(a.distance) > parseFloat(b.distance) ? 1 : -1;
        })
        this.$store.commit('updateLocations', this.locations);
      });
    },

    haversine(start, end) {
      var RADII = 3960; // Miles.

      var toRad = function (num) {
        return num * Math.PI / 180
      }

      var dLat = toRad(end.lat - start.lat)
      var dLon = toRad(end.lng - start.lng)
      var lat1 = toRad(start.lat)
      var lat2 = toRad(end.lat)

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

      return parseFloat(RADII * c || 0).toFixed(0);
    },
    logout() {
      this.$store.commit('authenticate', false);
      axios.get(this.$api.logout).then(response => {
        this.$router.push({ name: 'login' });
      }).catch(error => {
        if(error.response.status === 401) {
          this.$router.push({ name: 'login' });
        }
      });
    }
  },

  created: function() {
    this.timer = window.setInterval(getPosition => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const lat = position.coords.latitude || 0;
          const lng = position.coords.longitude || 0;
          this.$store.commit('updatePosition', { lat, lng });
          this.map.setCenter(new google.maps.LatLng(lat, lng));
        });
      }
      this.filterLocations();
    }, 600000); //10 minute location updates
  },

  mounted: function() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoomControl: true,
      gestureHandling: 'cooperative'
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude || 0;
        const lng = position.coords.longitude || 0;
        this.$store.commit('updatePosition', { lat, lng });
        this.map.setCenter(new google.maps.LatLng(lat, lng));
      });
    }
    this.getLocations();
    this.getMinors();
    this.filterLocations();
  },

  beforeDestroy() {
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  margin: 10px auto 0 auto;
  height: 56px;
  width: 300px;
  border: 0;
  border-radius: 3px;
  background-color: var(--color-cerulean-blue);
  font-weight: 400;
  font-family: var(--font-family-primary);
  font-size: 18px;
  color: var(--color-athens-gray);
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  outline: none;
  &:hover {
    cursor: pointer;
    background-color: var(--color-tundora);
  }

  &:disabled {
    background-color: var(--color-gray);
    cursor: default;
  }
}

.container {
  display: grid;
  justify-content: center;
}

#map {
  margin: 0 auto;
}

.hidden {
  display: none;
}

.background-header {
  width: 100%;
  box-shadow: 0 2px 24px rgba(115, 137, 156, 0.31);
  background-color: var(--color-white);
  height: 20vh;
  position: absolute;
  top: 0;
  z-index: 1;
}

.locations {
  padding-bottom: 25px;
}

.locations__item {
   cursor: pointer;
}

.content {
  width: 768px;
  position: relative;
  z-index: 2;
}

.content-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 60px 0 25px 0;

  .logo {
    cursor: pointer;
    img {
      width: auto;
      height: 60px;
    }
  }
  .account {
    text-align: right;
    color: var(--color-dove-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 60px;
    display: inline-block;

    .icon-lock {
      margin-left: 15px;
      font-size: 25px;
      vertical-align: middle;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.filter {
  border-radius: 3px;
  background-color: var(--color-cerulean-blue);
  display: grid;
  grid-template-columns: 80% 20%;
  padding: 25px 25px 10px 25px;
  font-family: var(--font-family-secondary);

  .left {
    margin-right: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: grid;
    gap: 0 15px;
  }
  .right {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    padding-left: 15px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    .toggle {
      background: transparent;
      border: 0;
      color: var(--color-danube);
      font-size: 16px;
      font-weight: 400;
      font-family: var(--font-family-primary);
      cursor: pointer;
      transition-property: background-color, color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      text-align: left;
      text-indent: 25%;

      &.active,
      &:hover {
        color: var(--color-athens-gray);
      }
    }

    .icon {
      font-size: 20px;
      position: relative;
      top: 3px;
    }
  }

  .option {
    background: transparent;
    border: 1px solid var(--color-white);
    color: var(--color-white);
    font-family: var(--font-family-secondary);
    border-radius: 3px;
    font-size: 13px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &.active,
    &:hover {
      background: var(--color-white);
      color: var(--color-tundora);
    }
  }

  .radius {
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 20px;
      cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 12px;
      height: 12px;
      border-radius: 3px;
      background: #fff;
    }

    [type="checkbox"]:checked + label:before {
      // border-color: var(--color-tundora);
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 2px;
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background: var(--color-ocean-green);
    }
    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }
    [type="checkbox"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  .radius {
    grid-column: span 3;
    text-align: right;
    margin-top: 22px;
    color: var(--color-white);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }

  .clear {
    border: 0;
    grid-column: span 1;
    background: transparent;
    text-align: left;
    margin-top: 15px;
    color: var(--color-white);
    font-family: var(--font-family-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;
    outline: none;

    i {
      font-size: 16px;
      padding-right: 10px;
      vertical-align: middle;
      line-height: 30px;
      position: relative;
      top: -2px;
    }
  }

  .search {
    grid-column: span 4;
    border-radius: 3px;
    background-color: rgb(232, 234, 237, 0.2);
    margin-bottom: 20px;
    border: 0;
    padding: 20px;
    color: var(--color-white);
    font-family: var(--font-family-secondary);

    &::placeholder {
      color: rgb(255, 255, 255, 0.5);
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.map .row {
  cursor: pointer;
   height: 100px;
}

.row {
  background-color: var(--color-white);
  border-radius: 3px;
  margin: 25px 0;
  padding: 10px 40px;
  display: grid;
  grid-template-columns: 90% 10%;
  justify-items: left;
  height: 100%;
  align-content: center;

  .left,
  .right {
    width: 100%;
    height: 100%;
    display: grid;
  }

  .top {
    line-height: 30px;
    align-items: center;
  }

  .bottom {
    margin-top: 15px;
    line-height: 30px;
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
  }

  .title {
    color: var(--color-tundora);
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: 500;
    margin-right: 15px;
  }

  .tags {
    color: var(--color-white);
    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;
    line-height: 11px;

    .passed,
    .failed,
    .synar,
    .recheck {
      border-radius: 12px;
      padding: 5px 10px;
      text-transform: capitalize;
      margin-right: 10px;
    }
    .passed {
      background-color: var(--color-ocean-green);
    }
    .failed {
      background-color: var(--color-carnation);
    }
    .synar {
      color: var(--color-gray);
      background-color: var(--color-white);
      border: 1px solid var(--color-shiny-silver);
    }
    .recheck {
      background-color: var(--color-cerulean-blue);
    }
  }

  .distance {
    color: var(--color-gray);
    font-size: 11px;
    font-weight: 400;
    border-radius: 12px;
    background-color: var(--color-athens-gray);
    padding: 0 8px;
    width: 30%;
    height: 20px;
    text-align: center;
    line-height: 20px;

    &:empty {
      display: none;
    }
  }

  .address {
    color: var(--color-gray);
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    padding-right: 15px;
    line-height: 20px;
  }

  .arrow {
    border: 0;
    justify-self: end;
    font-size: 40px;
    line-height: 25px;
    color: var(--color-shiny-silver);
    background-color: var(--color-white);
    align-self: center;
    cursor: pointer;
  }
}

.map {
  width: 100%;
  position: absolute;
  height: 80vh;
  top: 20vh;
  overflow: hidden;
}
.map .row {
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: -80%;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(768px - 80px);
}
</style>
