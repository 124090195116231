<template>
  <div class="container">
    <div class="background-header"></div>
    <div class="content-header">
      <div class="back" @click="goToLocations()"><i class="icon icon-arrow-left" /> <span class="text">Back</span></div>
      <div class="account">
        {{ $store.getters.account.first_name }} {{ $store.getters.account.last_name }}
        <i class="icon icon-lock" @click="logout()" />
      </div>
    </div>
    <div class="content">
      <div class="details">
        <div class="top">
          <div>
            <span class="name">{{ location.name }}</span>
            <br><br>
            <span class="tags">
              <template v-if="location.is_synar">
                 <span class="synar">Synar Store</span>
              </template>
              <template v-if="!location.needs_recheck && location.compliance_checks.length > 0 && location.compliance_checks[0].passed">
                 <span class="passed">Passed</span>
              </template>
              <template v-else-if="!location.needs_recheck && location.compliance_checks.length > 0">
                 <span class="failed">Failed</span>
              </template>
              <template v-if="location.needs_recheck">
                <span class="recheck">Recheck</span>
              </template>
           </span>
          </div>
        </div>
        <div>
          <div class="header">Store Address</div>
          <span class="content">
            {{ location.address }}, <br />
            {{ location.city }}, {{ location.state }} {{ location.zipcode }}
          </span>
        </div>
        <div>
          <div class="header">Store Category</div>
          <span class="content classification">
            {{ location.classification }}
          </span>
        </div>
      </div>
      <div class="inspections">
        <span class="heading">Previous Inspections</span>
        <div class="inspection" v-for="inspection in checks" v-bind:key="inspection.id">
          <div class="tags">
            <template v-if="inspection.is_synar">
               <span class="synar">Synar Store</span>
            </template>
            <template v-if="!inspection.needs_recheck && inspection.passed">
               <span class="passed">Passed</span>
            </template>
            <template v-else-if="!inspection.needs_recheck && inspection.passed === false">
               <span class="failed">Failed</span>
            </template>
            <template v-if="inspection.needs_recheck">
              <span class="recheck">Recheck</span>
            </template>
          </div>
          <div class="card" v-if="inspection.clerk_name.length">
            <div class="header">Clerk's Name</div>
            <div>{{ inspection.clerk_name }}</div>
          </div>
          <div class="card">
            <div class="header">Date</div>
            <div>{{ inspection.check_date }}</div>
          </div>
          <div class="card">
            <div class="header">Investigator</div>
            <div>{{ inspection.officers_name }}</div>
          </div>
          <div class="card">
            <div class="header">Status</div>
            <div>{{ inspection.status }}</div>
          </div>
          <div class="card" v-if="inspection.action_taken.length && inspection.action_taken !== 'Undefined'">
            <div class="header">Action Taken</div>
            <div>{{ inspection.action_taken }}</div>
          </div>
          <div class="card" v-if="getMinor(inspection.minor_id)">
            <div class="header">Minor Initials</div>
            <div>{{ getMinor(inspection.minor_id) ? getMinor(inspection.minor_id).initials : '' }}</div>
          </div>
        </div>
        <div v-if="location.compliance_checks.length > 3 && !show_all" class="more" @click="show_all = true"><span class="text">View More Inspections</span><i class="icon icon-arrow-right" /></div>
        <div v-if="location.compliance_checks.length > 3 && show_all" class="more" @click="show_all = false"><span class="text">View Less Inspections</span><i class="icon icon-arrow-right" /></div>
      </div>
      <div class="actions">
        <button class="button light" @click="getDirections()"><i class="icon icon-direction" /><br>Directions</button>
        <button class="button dark" @click="goToCompliance(location)"><i class="icon icon-check-list" /><br>Compliance Check</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      location: {
        compliance_checks: []
      },
      minors: {},
      show_all: false
    };
  },
  methods: {
    goToLocations() {
      this.$router.push({ name: 'locations' });
    },

    logout() {
      this.$store.commit('authenticate', false);

      axios.get(this.$api.logout).then(response => {
        this.$router.push({ name: 'login' });
      }).catch(error => {
        if(error.response.status === 401) {
          this.$router.push({ name: 'login' });
        }
      });
    },

    getDirections() {
      let address = [this.location.address, this.location.city, this.location.state, this.location.zipcode].join(' ');
      let position = this.$store.getters.position;
      let uri = '?saddr='+ 'My+Location' + '&daddr=' + address

      window.open(
        'https://maps.google.com/maps' + uri,
        '_blank'
        );
    },

    goToCompliance(location) {
      this.$router.push({
        name: 'check',
        params: location
      });
    },

    getMinor(id) {
      return this.minors[id];
    },
  },
  mounted: function() {
    this.location = this.$store.getters.active;
    this.minors = this.$store.getters.minors;
  },
  computed: {
    checks: function() {
      const checks = this.location.compliance_checks.sort((a, b) => (a.check_date_sortable < b.check_date_sortable) ? 1 : -1);

      return this.show_all ? checks : checks.slice(0, 3);
    }
  }
};
</script>

<style lang="scss" scoped>
.more {
  padding: 10px;
  width: 100%;
  text-align: right;
}
.back, .more {
  color: var(--color-cerulean-blue);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;

  .icon {
    margin-left: 15px;
    font-size: 20px;
    vertical-align: text-bottom;
  }
}

.container {
  display: grid;
  justify-content: center;
  background: var(--color-white);
  box-shadow: 0 2px 24px rgba(115, 137, 156, 0.31);
}

.background-header {
  width: 100%;
  box-shadow: 0 2px 24px rgba(115, 137, 156, 0.31);
  background-color: var(--color-white);
  height: 10vh;
  position: absolute;
  top: 0;
  z-index: 2;
}

.account {
  text-align: right;
  color: var(--color-dove-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;

  .icon-lock {
    margin-left: 15px;
    font-size: 25px;
    vertical-align: middle;
    font-weight: 700;
  }
}

.content {
  width: 768px;
  position: relative;
  z-index: 1;
}

.classification {
  text-transform: capitalize;
}

.content-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 30px 0 0 0;
  height: 5vh;
  position: relative;
  z-index: 3;
}

.tags {
  color: var(--color-white);
  font-size: 11px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 11px;
  grid-column: span 2;

  .passed,
  .failed,
  .synar,
  .recheck {
    border-radius: 12px;
    padding: 5px 10px;
    text-transform: capitalize;
    margin-right: 10px;
  }
  .passed {
    background-color: var(--color-ocean-green);
  }
  .failed {
    background-color: var(--color-carnation);
  }
  .synar {
    color: var(--color-gray);
    background-color: var(--color-white);
    border: 1px solid var(--color-shiny-silver);
  }
  .recheck {
    background-color: var(--color-cerulean-blue);
  }
}

.inspections {
  .tags {
    margin: 25px 0;
  }
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--color-athens-gray);

  .top {
    grid-column: span 2;
    margin-bottom: 25px;
    padding-top: 10vh;
  }

  .name {
    color: var(--color-tundora);
    font-family: var(--font-family-secondary);
    font-size: 36px;
    font-weight: 700;
    margin-right: 15px;
  }
}

.content {
  color: var(--color-tundora);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.header {
  color: var(--color-silver);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.inspections {
  padding: 0 0 25px;

  .heading {
    color: var(--color-tundora);
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: 500;
  }

  .inspection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    border-bottom: 1px solid var(--color-athens-gray);
    padding: 0 0 25px;

    .card {
      margin-bottom: 20px;
    }

  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  gap: 25px;
}

.button {
  border-radius: 3px;
  outline: none;
  border: 0;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  height: 150px;
  padding: 25px;
  margin: 0 0 100px;
  box-shadow: 0 0 10px #ececec;
  text-align: left;
  text-indent: -5px;

  .icon {
    font-size: 40px;
  }

  &.light {
    background-color: var(--color-white);
    color: var(--color-tundora);
  }

  &.dark {
    background-color: var(--color-cerulean-blue);
    color: var(--color-white);
  }
}
</style>
