<template>
  <div :class="{ error }">
    <div class="top">
      <h1 class="big">Tobacco</h1>
      <h2 class="small">Compliance App</h2>
    </div>
    <div class="bottom">
      <form class="form" :action="$api.login" method="POST" @submit.prevent="login($event)">
        <div class="message" v-if="error">{{ message }}</div>
        <label class="label"
          ><span class="text">Username</span>
          <input class="input" v-model="credentials.email" type="email" name="email" />
        </label>
        <label class="label"
          ><span class="text">Password</span>
          <input class="input" v-model="credentials.password" type="password" name="password" />
        </label>
        <div class="link-row">
          <a class="link" href="/forgot">Forgot Password</a>
        </div>
        <button type="submit" class="button">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data: function() {
    return {
      error: false,
      message: '',
      credentials: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    login() {
      this.error = false;
      this.message = '';

      if (this.credentials.email.length === 0 || this.credentials.password.length === 0) {
        this.error = true;
        this.message = 'Please provide a username and password.';
      }

      if (this.error === false) {
        axios
        .post(
          this.$api.login,
          {
            email: this.credentials.email,
            password: this.credentials.password
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        .then(response => {
          let redirectUrl = response.data.redirect_url;

          axios.get(this.$api.account).then(response => {
            this.$store.commit('authenticate', true);
            this.$store.commit('updateAccount', response.data);
            window.location = redirectUrl
          });
        })
        .catch(error => {
          this.error = true;
          this.message = 'There was an issue communicating with the server.';

          if(error.response.data === '["auth.failed"]') {
            this.message = 'Invalid credentials provided.';
          }
        });
      }
    }
  },
  mounted() {
    // //this.stub();
      if(this.$store.getters.isAuthenticated){
        this.$router.push({ name: 'locations'});
      }
    }
  };
</script>

<style lang="scss" scoped>
.top,
.bottom {
  width: 100vw;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
.top {
  box-shadow: 0 2px 24px rgba(115, 137, 156, 0.31);
  background-color: var(--color-white);
  text-align: center;
  position: relative;
  z-index: 2;
  height: 40vh;
  align-content: center;
}
.bottom {
  height: 60vh;
  align-content: flex-start;
}
.big,
.small {
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
}
.big {
  color: var(--color-azure);
  font-size: 80px;
  font-weight: 700;
}
.small {
  color: var(--color-pickled-bluewood);
  font-size: 24px;
  font-weight: 400;
}
.form {
  display: grid;
  align-content: center;
  grid-template-columns: 435px;
  margin-top: 100px;
}
.label {
  color: var(--color-pickled-bluewood);
  font-size: 14px;
  font-weight: 400;
  .text {
    margin: 10px 0;
    display: block;
  }
}
.input {
  width: calc(100% - 23px);
  height: 56px;
  border-radius: 3px;
  border: 1px solid;
  border-color: var(--color-lynch);
  background-color: var(--color-wild-sand);
  padding: 0 10px;
  font-size: 18px;
  margin: 0;
  outline: none;
}
.link-row {
  text-align: center;
}
.link {
  display: inline-block;
  text-align: center;
  color: var(--color-pickled-bluewood);
  font-size: 13px;
  padding-top: 10px;
  text-decoration: none;
  margin: 10px 0 0 0;
  font-weight: 400;
  padding-bottom: 0.5em;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.2s ease-in-out;
  &:hover {
    border-bottom: 2px solid;
    border-color: var(--color-pickled-bluewood);
    cursor: pointer;
  }
}
.button {
  margin: 10px auto 0 auto;
  align-self: center;
  width: 285px;
  height: 56px;
  border: 0;
  border-radius: 3px;
  background-color: var(--color-cerulean-blue);
  font-weight: 400;
  font-family: var(--font-family-primary);
  font-size: 18px;
  color: var(--color-athens-gray);
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  outline: none;
  &:hover {
    cursor: pointer;
    background-color: var(--color-tundora);
  }
}
.error {
  .input {
    border-color: var(--color-carnation);
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  .message {
    display: block;
    color: var(--color-carnation);
  }
  .button {
    background-color: var(--color-carnation);
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    color: var(--color-white);
    border: 0;

    &:hover {
      background-color: var(--color-carnation);
    }
  }
}

@keyframes shake {
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
