<template>
  <div class="container">
    <div class="background-header"></div>
    <div class="content-header">
      <div class="back" @click="goToLocation(location)"><i class="icon icon-arrow-left" /> <span class="text">Back</span></div>
      <div class="account">
        {{ $store.getters.account.first_name }} {{ $store.getters.account.last_name }}
        <i class="icon icon-lock" @click="logout()" />
      </div>
    </div>
    <div class="content">
      <div class="details">
        <div class="top">
          <span class="title">Tobacco Compliance Check</span>
        </div>
        <div>
          <div class="header">Store Name</div>
          <span class="content">{{ location.name }}</span>
        </div>
        <div>
          <div class="header">Store Address</div>
          <span class="content">
            {{ location.address }}, {{ location.city }}, {{ location.state }} {{ location.zipcode }}
          </span>
        </div>
      </div>
      <div class="synar">
        <div class="header">Synar Store</div>
        <span class="content">
          {{ location.is_synar ? 'Yes' : 'No' }}
        </span>
      </div>
      <div class="section">
        <div id="autocomplete" class="autocomplete minor-id" :class="errors.minor_id ? 'error' : null">
          <div class="header">Minor ID</div>
          <input class="input autocomplete-input" />
          <ul class="autocomplete-result-list"></ul>
        </div>

        <div class="minor-information two-column" v-if="check.minor_id">
          <div>
            <div class="header">Minor's Age</div>
            {{ getAge(minor.birth_date) }}
          </div>
          <div>
            <div class="header">Minor's DOB</div>
            {{ minor.birth_date }}
          </div>
        </div>

        <div class="statuses" :class="errors.status ? 'error' : null">
          <div class="header">Status</div>
          <div class="items">
            <div class="item" @click="setStatus(status.value); setRecheck(status.recheck); " v-for="status in this.$statuses" :class="{ 'active': check.status === status.value }" v-bind:key="status.value">
              {{ status.label }}
            </div>
          </div>
        </div>

        <div class="notes">
          <div class="header">Notes</div>
          <input @keyup="setNotes($event)" class="input" />
        </div>

        <div class="recheck boolean" :class="errors.needs_recheck ? 'error' : null">
          <div class="header">Recheck</div>
          <div class="items">
            <div @click="setRecheck(value)" :class="{ 'active': check.needs_recheck === parseFloat(value) }" class="item" v-for="(recheck, value) in options.recheck" v-bind:key="recheck">
              {{ recheck }}
            </div>
          </div>
        </div>
        <div class="signage" v-if="check.status === 'checked' || check.status.length === 0" :class="errors.appropriate_signage ? 'error' : null">
          <div class="header">Was There Appropriate Signage?</div>
          <div class="items">
            <div @click="setAppropriateSignage(value)" :class="{ 'active': check.appropriate_signage === value }" class="item" v-for="(signage, value) in options.signage" v-bind:key="signage">
              {{ signage }}
            </div>
          </div>
        </div>
      </div>
      <div class="section" v-show="check.status === 'checked' || check.status.length === 0">

        <div class="gender boolean" :class="errors.clerk_gender ? 'error' : null">
          <div class="header">Retail Clerk Gender</div>
          <div class="items">
            <div @click="setClerkGender(gender)" :class="{ 'active': check.clerk_gender === gender }" class="item" v-for="(gender) in options.gender" v-bind:key="gender">
              {{ gender }}
            </div>
          </div>
        </div>

        <div class="clerk-information two-column">
          <div :class="errors.clerk_name ? 'error' : null">
            <div class="header">Retail Clerk’s Name</div>
            <input @keyup="setClerkName($event)" class="input" />
          </div>
          <div :class="errors.clerk_position ? 'error' : null">
            <div class="header">Retail Clerk’s Position</div>
            <select @change="setClerkPosition($event)">
              <option value="">--Please choose an option--</option>
              <option value="Clerk">Clerk</option>
              <option value="Owner">Owner</option>
              <option value="Manager">Manager</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        <div class="training boolean" :class="errors.clerk_trained ? 'error' : null">
          <div class="header">Has the Clerk Received Training on Tobacco Laws?</div>
          <div class="items">
            <div @click="setClerkTrained(value)" :class="{ 'active': check.clerk_trained === parseFloat(value) }" class="item" v-for="(training, value) in options.training" v-bind:key="training">
              {{ training }}
            </div>
          </div>
        </div>

        <div class="two-column">
          <div class="id-requested boolean" :class="errors.id_requested ? 'error' : null">
            <div class="header">ID Requested</div>
            <div class="items">
              <div @click="setIdRequested(value)" :class="{ 'active': check.id_requested === parseFloat(value) }" class="item" v-for="(request, value) in options.id_requested" v-bind:key="request">
                {{ request }}
              </div>
            </div>
          </div>
          <div class="age-requested boolean" :class="errors.age_requested ? 'error' : null">
            <div class="header">Age Asked</div>
            <div class="items">
              <div @click="setAgeRequested(value)" :class="{ 'active': check.age_requested === parseFloat(value) }" class="item" v-for="(ask, value) in options.age_asked" v-bind:key="ask">
                {{ ask }}
              </div>
            </div>
          </div>
        </div>
        <div class="two-column">
          <div class="sale-information boolean" :class="errors.sale_completed ? 'error' : null">
            <div class="header">Sale Completed</div>
            <div class="items">
              <div @click="setSaleCompleted(value)" :class="{ 'active': check.sale_completed === parseFloat(value) }" class="item" v-for="(completed, value) in options.sale_completed" v-bind:key="completed">
                {{ completed }}
              </div>
            </div>
          </div>
          <div class="witnessed boolean" :class="errors.officer_witnessed_transaction ? 'error' : null">
            <div class="header">Officer Witnessed Transaction</div>
            <div class="items">
              <div @click="setOfficerWitnessedTransaction(value)" :class="{ 'active': check.officer_witnessed_transaction === parseFloat(value) }" class="item" v-for="(witnessed, value) in options.witnessed" v-bind:key="witnessed">
                {{ witnessed }}
              </div>
            </div>
          </div>
        </div>

        <div class="purchases" :class="errors.attempted_purchased_item ? 'error' : null">
          <div class="header">Attempted Purchase Item</div>
          <div class="items">
            <div @click="setAttemptedPurchasedItem(purchase.value)" :class="{ 'active': check.attempted_purchased_item === purchase.value }" class="item" v-for="purchase in this.$items" v-bind:key="purchase.value">
              {{ purchase.label }}
            </div>
          </div>
        </div>
        <div class="action-taken" :class="errors.action_taken ? 'error' : null">
          <div class="header">Action Taken</div>
          <div class="items">
            <div @click="setActionTaken(action)" :class="{ 'active': check.action_taken === action }" class="item" v-for="(action) in options.action" v-bind:key="action">
              {{ action }}
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <button class="button" @click="submit(location)">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-js';
import axios from 'axios';
import router from '../routes';


export default {
  data: function() {
    return {
      location: {},
      minors: {},
      minor: {},
      errors: {
        store_id: false,
        minor_id: false,
        check_timestamp: false,
        status: false,
        notes: false,
        appropriate_signage: false,
        clerk_gender: false,
        clerk_name: false,
        clerk_position: false,
        clerk_trained: false,
        id_requested: false,
        age_requested: false,
        sale_completed: false,
        officer_witnessed_transaction: false,
        attempted_purchased_item: false,
        action_taken: false,
        needs_recheck: false
      },
      check: {
        store_id: '',
        minor_id: '',
        check_timestamp: '',
        status: '',
        notes: '',
        appropriate_signage: '',
        clerk_gender: '',
        clerk_name: '',
        clerk_position: '',
        clerk_trained: '',
        id_requested: '',
        age_requested: '',
        sale_completed: '',
        officer_witnessed_transaction: '',
        attempted_purchased_item: '',
        action_taken: '',
        needs_recheck: ''
      },
      options: {
        signage: {
          yes: 'Yes',
          no_citation: 'No - Citation Issued',
          no_warning: 'No - Warning Issued'
        },
        gender: [
          'male',
          'female'
        ],
        training: {
          1: 'Yes',
          0: 'No'
        },
        recheck: {
          1: 'Yes',
          0: 'No'
        },
        id_requested: {
          1: 'Yes',
          0: 'No'
        },
        age_asked: {
          1: 'Yes',
          0: 'No'
        },
        sale_completed: {
          1: 'Yes',
          0: 'No'
        },
        witnessed: {
          1: 'Yes',
          0: 'No'
        },
        action: [
          'warning',
          'citation',
          'none'
        ]
      },
    };
  },
  methods: {
    submit() {
      let d = new Date,
      dformat = [d.getMonth()+1,
               d.getDate(),
               d.getFullYear()].join('/')+' '+
              [d.getHours(),
               d.getMinutes(),
               d.getSeconds()].join(':');

      this.check.check_timestamp = dformat;

      let valid = true;

      let data = {
        store_id: 0,
        check_timestamp: 0,
        status: 0,
        needs_recheck: 0
      }

      data.check_timestamp = this.check.check_timestamp;
      data.store_id = this.check.store_id;

      if (this.check.status.length === 0) {
        valid = false;
        this.errors.status = true;
      } else {
        this.errors.status = false;
        data.status = this.check.status
      }

      // minor_id is always required.
      if(this.check.minor_id.length === 0) {
        valid = false;
        this.errors.minor_id = true;
      } else {
        this.errors.minor_id = false;
        data.minor_id = this.check.minor_id
      }

      // Notes are not required.
      data.notes = this.check.notes

      if (this.check.needs_recheck !== 0 && this.check.needs_recheck !== 1) {
        valid = false;
        this.errors.needs_recheck = true;
      } else {
        this.errors.needs_recheck = false;
        data.needs_recheck = this.check.needs_recheck
      }

      // These fields are only required if we selected "Checked" as a status.
      if (this.check.status === 'checked' || this.check.status.length === 0) {

        if(this.check.appropriate_signage.length === 0) {
          valid = false;
          this.errors.appropriate_signage = true;
        } else {
          this.errors.appropriate_signage = false;
          data.appropriate_signage = this.check.appropriate_signage
        }
        if(this.check.clerk_gender.length === 0) {
          valid = false;
          this.errors.clerk_gender = true;
        } else {
          this.errors.clerk_gender = false;
          data.clerk_gender = this.check.clerk_gender
        }
        if(this.check.clerk_name.length === 0) {
          valid = false;
          this.errors.clerk_name = true;
        } else {
          this.errors.clerk_name = false;
          data.clerk_name = this.check.clerk_name
        }
        if(this.check.clerk_position.length === 0) {
          valid = false;
          this.errors.clerk_position = true;
        } else {
          this.errors.clerk_position = false;
          data.clerk_position = this.check.clerk_position
        }
        if(this.check.clerk_trained.length === 0) {
          valid = false;
          this.errors.clerk_trained = true;
        } else {
          this.errors.clerk_trained = false;
          data.clerk_trained = this.check.clerk_trained
        }
        if(this.check.id_requested.length === 0) {
          valid = false;
          this.errors.id_requested = true;
        } else {
          this.errors.id_requested = false;
          data.id_requested = this.check.id_requested
        }
        if(this.check.age_requested.length === 0) {
          valid = false;
          this.errors.age_requested = true;
        } else {
          this.errors.age_requested = false;
          data.age_requested = this.check.age_requested
        }
        if(this.check.sale_completed.length === 0) {
          valid = false;
          this.errors.sale_completed = true;
        } else {
          this.errors.sale_completed = false;
          data.sale_completed = this.check.sale_completed
        }
        if(this.check.officer_witnessed_transaction.length === 0) {
          valid = false;
          this.errors.officer_witnessed_transaction = true;
        } else {
          this.errors.officer_witnessed_transaction = false;
          data.officer_witnessed_transaction = this.check.officer_witnessed_transaction
        }
        if(this.check.attempted_purchased_item.length === 0) {
          valid = false;
          this.errors.attempted_purchased_item = true;
        } else {
          this.errors.attempted_purchased_item = false;
          data.attempted_purchased_item = this.check.attempted_purchased_item
        }
        if(this.check.action_taken.length === 0) {
          valid = false;
          this.errors.action_taken = true;
        } else {
          this.errors.action_taken = false;
          data.action_taken = this.check.action_taken
        }
      }

     //Checks to see if user has internet connection
     if(!this.$store.getters.isOnline) {
        //Stores form data in local storage if offline
        this.$store.commit('updateChecks', this.check);
        this.$store.commit('updateValid', valid);
        this.$store.commit('updateData', data);
        alert("Your form will submit when you are back online.");
        this.$router.push({name: 'locations'});
      } else {
        if(valid) {
          axios
          .post(
            this.$api.check,
            data,
            { headers: { 'Content-Type': 'application/json' } }
          )
          .then(response => {
            this.$router.push({ name: 'locations' });
            alert('Form submitted successfully!')
          })
          .catch(error => {
            this.message = 'There was an issue communicating with the server.';
            console.log('%c' + this.message, this.$log.error);

            //logs user out if they are no longer authenticated
            if(error.response.status === 401) {
              alert('Oops! Looks like you were logged out. Please log back in.');
              //Stores form data in local until they are logged back in
              this.$store.commit('updateChecks', this.check);
              this.$store.commit('updateValid', valid);
              this.$store.commit('updateData', data);
              this.$store.commit('authenticate', false);
              this.$router.push({ name: 'login' });
            }
          });
        } else {
          this.$forceUpdate();
          this.message = 'Invalid data.';
          console.log('%c' + this.message, this.$log.error);
        }
      }
    },

    getAge(birthday) {

      //manually parse birthday because Apple hates Date.parse()
      var b = birthday.split(/\D/);
      var parsedBirthday = new Date(b[2], b[0]-1, b[1]);

      let ageDifMs = Date.now() - parsedBirthday;
      let ageDate = new Date(ageDifMs);

      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    setStoreId(id) {
      this.check.store_id = id
    },

    setMinorId(minor) {
      this.minor = minor;
      this.check.minor_id = minor.id
    },

    setNotes(notes) {
      this.check.notes = notes.target.value
    },

    setAppropriateSignage(signage) {
      this.check.appropriate_signage = signage
    },

    setClerkGender(gender) {
      this.check.clerk_gender = gender
    },

    setClerkName(name) {
      this.check.clerk_name = name.target.value
    },

    setClerkPosition(position) {
      this.check.clerk_position = position.target.value
    },

    setRecheck(recheck) {
      let value = recheck;
      if(typeof recheck === 'boolean') {
        value = recheck ? 1 : 0;
      } else {
        value = parseFloat(recheck)
      }
      this.check.needs_recheck = value
    },

    setClerkTrained(trained) {
      this.check.clerk_trained = parseInt(trained)
    },

    setIdRequested(requested) {
      this.check.id_requested = parseInt(requested)
    },

    setAgeRequested(age) {
      this.check.age_requested = parseInt(age)
    },

    setSaleCompleted(completed) {
      this.check.sale_completed = parseInt(completed)
    },

    setOfficerWitnessedTransaction(witnessed) {
      this.check.officer_witnessed_transaction = parseInt(witnessed)
    },

    setAttemptedPurchasedItem(item) {
      this.check.attempted_purchased_item = item
    },

    setActionTaken(action) {
      this.check.action_taken = action
    },

    setStatus(status) {
      this.check.status = status
    },

    goToLocation(location) {
      this.$router.push({
        name: 'location',
        params: location
      });
    },

    logout() {
      this.$store.commit('authenticate', false);

      axios.get(this.$api.logout).then(response => {
        this.$router.push({ name: 'login' });
      }).catch(error => {
        if(error.response.status === 401) {
          this.$router.push({ name: 'login' });
        }
      });
    },

    getDirections() {
      let address = [this.location.address, this.location.city, this.location.state, this.location.zipcode].join(' ');
      let position = this.$store.getters.position;
      let uri = '?q='+ 'current location' + ' to ' + address

      window.open(
        'https://www.google.com/maps/dir/' + uri,
        '_blank'
        );
    },

    goToCompliance(location) {
      this.$router.push({
        name: 'check',
        params: location
      });
    },
    createDropDown() {
      new Autocomplete('#autocomplete', {
        search: input => {
          if (input.length < 1) { return [] }

          return this.minors.filter(minor => {
            return minor.id.toLowerCase().startsWith(input.toLowerCase())
          })
        },
        autoSelect: true,
        getResultValue: result => result.id,
        onSubmit: result => {
          this.setMinorId(result)
        }
      })
    }
  },
  mounted: function() {
    this.location = this.$store.getters.active;
    this.setStoreId(this.location.id)
    this.minors = this.$store.getters.minors;
    this.createDropDown();
  }
};
</script>

<style lang="scss">
  // Yeah, this whole section isn't scoped because of the plugin ¯\_(ツ)_/¯
  .autocomplete-input {
  	border: 1px solid #eee;
  	border-radius: 8px;
  	width: 100%;
  	padding: 12px 12px 12px 48px;
  	box-sizing: border-box;
  	position: relative;
  	font-size: 16px;
  	line-height: 1.5;
  	flex: 1;
  	background-color: #eee;
  	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
  	background-repeat: no-repeat;
    background-position: center right 10px;
  	outline: none;
  }

  .autocomplete-input:focus,
  .autocomplete-input[aria-expanded=true] {
  	border-color: rgba(0, 0, 0, .12);
  	background-color: #fff;
  	outline: none;
  }

  [data-position=below] .autocomplete-input[aria-expanded=true] {
  	border-bottom-color: transparent;
  	border-radius: 8px 8px 0 0
  }

  [data-position=above] .autocomplete-input[aria-expanded=true] {
  	border-top-color: transparent;
  	border-radius: 0 0 8px 8px;
  	z-index: 2
  }

  .autocomplete[data-loading=true]:after {
  	content: "";
  	border: 3px solid rgba(0, 0, 0, .12);
  	border-right-color: rgba(0, 0, 0, .48);
  	border-radius: 100%;
  	width: 20px;
  	height: 20px;
  	position: absolute;
  	right: 12px;
  	top: 50%;
  	transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }

  .autocomplete-result-list {
  	margin: 0;
  	border: 1px solid rgba(0, 0, 0, .12);
  	padding: 0;
  	box-sizing: border-box;
  	max-height: 296px;
    overflow-y: auto;
    overflow-x: hidden;
  	background: #fff;
    list-style: none;
    width: 275px !important;
    box-shadow: 0 2px 2px rgba(115, 137, 156, 0.31);
  }

  [data-position=below] .autocomplete-result-list {
  	margin-top: -1px;
  	border-top-color: transparent;
  	border-radius: 0 0 8px 8px;
  	padding-bottom: 8px
  }

  [data-position=above] .autocomplete-result-list {
  	margin-bottom: -1px;
  	border-bottom-color: transparent;
  	border-radius: 8px 8px 0 0;
  	padding-top: 8px
  }

  .autocomplete-result {
    cursor: pointer;
  	padding: 12px;
  	background-repeat: no-repeat;
  	background-position: 12px
  }

  .autocomplete-result:hover {
    background-color: var(--color-tundora);
    color: var(--color-white);
  }
  @keyframes rotate{0%{transform:translateY(-50%) rotate(0deg)}to{transform:translateY(-50%) rotate(359deg)}}
</style>

<style lang="scss" scoped>
.autocomplete {
  margin-bottom: 25px;
}

.error * {
  border-color: red !important;
  color: red !important;
}

.minor-id .input,
.clerk-information .input {
  width: 275px;
  height: 46px;
  border-radius: 3px;
  border: 1px solid #cbcbcb;
  background-color: #ffffff;
  padding: 0 10px;
  font-size: 18px;
  outline: none;
}

.clerk-information,
.minor-information {
  margin-bottom: 25px;
}

.two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px 20px;
}

.boolean {
  margin-bottom: 25px;
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 400px;

    .item {
      padding: 20px;
      border-radius: 2px;
      border: 1px solid var(--color-shiny-silver);
      background-color: var(--color-white);
      color: var(--color-gray);
      font-size: 11px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      transition-property: background-color, color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      position: relative;
      text-transform: capitalize;

      &:last-child {
        left: -1px;
      }

      &:hover, &.active {
        background-color: var(--color-jordy-blue);
        color: var(--color-pickled-bluewood);
      }
    }
  }
}

.statuses,
.signage,
.action-taken,
.purchases {
  margin-bottom: 25px;
}

.action-taken,
.signage {
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-athens-gray);
}

.statuses .items,
.signage .items,
.purchases .items,
.action-taken .items {
  text-transform: capitalize;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  align-items: center;
  gap: 25px 20px;

  .item {
    padding: 20px 0;
    border-radius: 2px;
    border: 1px solid var(--color-shiny-silver);
    background-color: var(--color-white);
    color: var(--color-gray);
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &:hover, &.active {
      background-color: var(--color-jordy-blue);
      color: var(--color-pickled-bluewood);
    }
  }
}

.notes {
  margin-bottom: 25px;

  .input {
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 3px;
    border: 1px solid var(--color-shiny-silver);
    background-color: var(--color-white);
  }
}



.back {
  color: var(--color-cerulean-blue);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;

  .icon {
    margin-left: 15px;
    font-size: 20px;
    vertical-align: text-bottom;
  }
}

.container {
  display: grid;
  justify-content: center;
  background: var(--color-white);
  box-shadow: 0 2px 24px rgba(115, 137, 156, 0.31);
}

.background-header {
  width: 100%;
  box-shadow: 0 2px 24px rgba(115, 137, 156, 0.31);
  background-color: var(--color-white);
  height: 10vh;
  position: absolute;
  top: 0;
  z-index: 2;
}

.account {
  text-align: right;
  color: var(--color-dove-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;

  .icon-lock {
    margin-left: 15px;
    font-size: 25px;
    vertical-align: middle;
    font-weight: 700;
  }
}

.content {
  width: 768px;
  position: relative;
  z-index: 1;
}

.content-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 30px 0 0 0;
  height: 5vh;
  position: relative;
  z-index: 3;
}

.tags {
  color: var(--color-white);
  font-size: 11px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 11px;
  grid-column: span 2;

  .passed,
  .failed,
  .synar {
    border-radius: 12px;
    padding: 5px 10px;
    text-transform: capitalize;
    margin-right: 10px;
  }
  .passed {
    background-color: var(--color-ocean-green);
  }
  .failed {
    background-color: var(--color-carnation);
  }
  .synar {
    color: var(--color-gray);
    background-color: var(--color-white);
    border: 1px solid var(--color-shiny-silver);
  }
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 0 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--color-athens-gray);

  .top {
    grid-column: span 2;
    margin-bottom: 50px;
  }

  .title {
    color: var(--color-tundora);
    font-family: var(--font-family-secondary);
    font-size: 36px;
    font-weight: 600;
    padding-top: 10vh;
    display: block;
  }
}

.content {
  color: var(--color-tundora);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.header {
  color: var(--color-silver);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.button {
  margin: 10px auto 0 auto;
  height: 56px;
  width: 300px;
  border: 0;
  border-radius: 3px;
  background-color: var(--color-cerulean-blue);
  font-weight: 400;
  font-family: var(--font-family-primary);
  font-size: 18px;
  color: var(--color-athens-gray);
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  outline: none;
  margin-bottom: 50px;

  &:hover {
    cursor: pointer;
    background-color: var(--color-tundora);
  }

  &:disabled {
    background-color: var(--color-gray);
    cursor: default;
  }
}

.synar {
  border-bottom: 1px solid var(--color-athens-gray);
  padding: 0 0 25px;
  margin: 0 0 25px;
}
</style>
